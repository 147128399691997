/** @jsx jsx */
import ProductsTemplate from '@bottlebooks/gatsby-theme-event-pages/src/templates/ProductsTemplate';
import { graphql } from 'gatsby';

export default ProductsTemplate;

export const pageQuery = graphql`
  query wosa_ProductsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        collectionId: eventId
        ...bb_ProductsPageBase_Collection
        registeredProducts {
          nodes {
            productId
            ...bb_ProductsPageBase
            ...wosa_productFilters
          }
        }
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
